<template>
  <div id="app">
    <AppHeader />
    <PageMenu :key="$route.path" />
    <transition
      :css="false"
      appear
      mode="out-in"
      @enter="enter"
      @leave="leave"
    >
      <router-view :key="$route.path" />
    </transition>
    <AppFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import medusa from '@/assets/js/observer';
import { isTouchDevice, is, isIOS } from '@/assets/js/utils';
import lazyload from '@/mixins/lazyload';

import AppHeader from '@/components/ui/header';
import AppFooter from '@/components/ui/footer';
import PageMenu from '@/components/ui/page-menu';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    PageMenu,
  },
  mixins: [lazyload],
  computed: {
    ...mapGetters(['currentPost']),
  },
  created() {
    medusa.init();
  },
  mounted() {
    // Redirect in local development
    if (window.location.port === '8888') {
      window.location.href = window.location.href.replace('8888', '3000');
    }

    window.addEventListener('resize', debounce(() => {
      this.$bus.$emit('windowResized');
    }, 400));

    this.$bus.$on('windowResized', this.resize);
    this.resize();
  },
  methods: {
    resize() {
      if (isIOS()) {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      } else {
        document.documentElement.style.setProperty('--vh', '1vh');
      }

      const breakpoints = window.getComputedStyle(document.documentElement).getPropertyValue('--breakpoints').replace(/[ '"]+/g, '');
      this.$store.commit('SET_BREAKPOINTS', breakpoints);
      this.$store.commit('SET_TOUCH', isTouchDevice());
      this.$store.commit('SET_MOBILE', is('m'));
    },
    enter(el, done) {
      done();
    },
    leave(el, done) {
      done();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/style.scss';

</style>
