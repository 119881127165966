<template>
  <footer
    v-if="options"
    v-show="currentPost"
  >
    <Spacer :x="{default: 'm', m: 'l'}">
      <div>
        <h1>
          <div class="logo" />
        </h1>
      </div>
      <div
        v-if="options && options.description"
        v-html="options.description"
      />
      <div
        v-if="options && options.list"
        align="h-between"
        class="list"
      >
        <div
          v-for="(item, index) in options.list"
          :key="index"
          align="h-between"
          class="list__item"
        >
          <div
            class="list__item--label"
            v-html="item.label"
          />
          <div
            class="list__item--content"
            v-html="item.content"
          />
        </div>
      </div>
      <div v-if="options && options.social">
        <div
          v-for="(item, index) in options.social"
          :key="index"
        >
          <a
            :href="item.url"
            v-html="item.label"
          />
        </div>
      </div>
      <div v-if="options && options.credits">
        <Flex
          v-for="(item, index) in options.credits"
          :key="index"
          align="h-between"
        >
          <div v-html="item.label" />
          <div v-html="item.content" />
        </Flex>
      </div>
    </Spacer>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Footer',
  computed: {
    ...mapGetters(['currentPost']),
    options() {
      if (this.$store.state.wp.options) {
        const { footer } = this.$store.state.wp.options;
        return footer;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
footer {

  background: $red;
  color: $white;
  padding: 6 * $unit 0 7 * $unit 0;
  @include mq(s) {

    padding: 8 * $unit 0;
  }
  @include mq(m) {

  }
}
.logo {
  width: 240px;
  height: 26px;
  background-image: url("~@/assets/img/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-bottom: 6 * $unit;
  @include mq(s) {
    margin: auto;
      margin-bottom: 6 * $unit;

  }
  @include mq(m) {
    width: 344px;
    height: 36px;
  }
}
.list {
  max-width: 1200px;
  margin: auto;
  @extend %typo--s;

  @include mq(s) {
    display: flex;
    justify-content: space-between;
  }
  .list__item {
    margin-bottom: 4 * $unit;
    &--label {
      margin-bottom: $unit;
      @extend %sans;
    }
    @include mq(s) {
      margin-bottom: 0;
      text-align: center;
    }
  }
  .list__item:last-child{
      margin-bottom: 0;
  }
}
</style>
