const components = new Map();

components.set('content', 'Content');
components.set('media', 'Media');
components.set('repeater', 'Repeater');
components.set('list', 'List');
components.set('archive', 'Archive');
components.set('hero', 'Hero');
components.set('marquee', 'Marquee');
components.set('newsletter', 'Newsletter');
components.set('accordion', 'Accordion');
components.set('placeholder', 'Custom');
components.set('search', 'Search');
components.set('raw_html', 'RawHtml');

export default components;
