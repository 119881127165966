import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

// eslint-disable-next-line no-undef
const { state } = __VUE_WORDPRESS__;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wp: state,
    breakpoints: null,
    touch: null,
    mobile: null,
    currentPost: null,
    lang: state.i18n ? state.i18n.default : 'default',
  },
  mutations,
  getters,
  actions,
  modules: {
  },
});
