<template>
  <section
    :id="layout.section_id ? layout.section_id : null"
    class="section"
    :class="[
      layout.section_classes !== '' ? layout.section_classes.split(' ') : null,
      layout.section_layout,

    ]"
  >
    <slot />
  </section>
</template>

<script>
export default {
  name: 'Section',
  props: {
    layout: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
