<template>
  <figure
    :class="[
      'picture',
      data.full_height ? 'full-height' : false,
      !data.ratio ? 'no-ratio' : false,
      `picture-${data.object_fit}`,
    ]"
    :data-lazy="data.lazyload ? 'false' : null"
  >
    <picture
      v-if="imageType !== 'svg'"
      :style="{ paddingBottom: !data.full_height && data.ratio ? `${ratio}%` : false }"
    >
      <source
        v-for="(image, key) in sizes"
        :key="key"
        :data-srcset="data.lazyload || skipLoad ? image.url : null"
        :srcset="!data.lazyload && !skipLoad ? image.url : null"
        :media="image.size"
      >
      <img
        ref="image"
        class="image"
        :data-src="data.lazyload || skipLoad ? defaultImage.sizes.l : null"
        :src="!data.lazyload && !skipLoad ? defaultImage.sizes.l : null"
        :alt="defaultImage.alt"
      >
      <div
        v-if="data.lazyload"
        class="placeholder"
      />
    </picture>
    <div
      v-else-if="imageType === 'inline-svg'"
      class="svg"
      v-html="data.svg"
    />
    <div v-else-if="imageType === 'svg'">
      <img
        class="svg"
        :src="defaultImage.url"
        :alt="defaultImage.alt"
      >
    </div>
    <Spacer
      v-if="data.caption && data.caption !== ''"
      y="s"
      :x="{ default: 's', xl: '0' }"
    >
      <figcaption
        class="typo--s"
        v-html="data.caption"
      />
    </Spacer>
  </figure>
</template>

<script>
export default {
  name: 'Figure',
  props: {
    data: {
      type: Object,
      required: true,
    },
    skipLoad: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    defaultImage() {
      if (this.data.default) {
        return this.data.default;
      }
      return this.data;
    },
    imageType() {
      if (this.data.type === 'svg') {
        return 'inline-svg';
      } if (this.defaultImage?.subtype === 'svg+xml') {
        return 'svg';
      }
      return 'image';
    },
    ratio() {
      if (!this.data && !this.defaultImage) return 0;
      return (this.defaultImage.height / this.defaultImage.width) * 100;
    },
    sizes() {
      if (!this.data && !this.defaultImage) return [];

      let mobileSizes = null;
      const { sizes } = this.defaultImage;
      if (this.data.mobile) {
        mobileSizes = this.data.mobile.sizes;
      }

      const ratio = window.devicePixelRatio > 1 ? 2 : 1;

      return {
        xxs: {
          url: mobileSizes?.xxs || sizes.xxs,
          size: `(max-width: ${(sizes['xxs-width']) / ratio}px)`,
        },
        xs: {
          url: mobileSizes?.xs || sizes.xs,
          size: `(max-width: ${(sizes['xs-width']) / ratio}px)`,
        },
        s: {
          url: mobileSizes?.s || sizes.s,
          size: `(max-width: ${(sizes['s-width']) / ratio}px)`,
        },
        m: {
          url: sizes.m,
          size: `(max-width: ${((sizes['m-width']) * 2) / ratio}px)`,
        },
        l: {
          url: sizes.l,
          size: `(max-width: ${((sizes['l-width']) * 2) / ratio}px)`,
        },
        xl: {
          url: sizes.xl,
          size: `(max-width: ${((sizes['xl-width']) * 2) / ratio}px)`,
        },
        xxl: {
          url: sizes.xxl,
          size: `(min-width: ${((sizes['xxl-width']) * 2) / ratio}px)`,
        },
      };
    },
  },
  mounted() {
    if (!this.data.lazyload && !this.skipLoad) {
      this.load();
    }
  },
  methods: {
    async load() {
      const img = this.$refs.image;

      if (this.skipLoad) {
        const sources = Array.from(this.$refs.image.parentNode.querySelectorAll('source'));
        sources.forEach((s) => {
          s.srcset = s.dataset.srcset;
        });

        this.$refs.image.src = this.$refs.image.dataset.src;
      }

      if (img && img.decode) {
        await img.decode();
        this.loaded = true;
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.picture {
  position: relative;
  overflow: hidden;

  picture {
    position: relative;
    overflow: hidden;
    display: block;
    height: 0;
  }

  &.no-ratio {
    picture {
      height: auto;
    }

    .image {
      position: relative;
      height: auto;
      width: auto;
      max-width: 100%;
    }
  }

  &.full-height {
    picture {
      height: 70vh;
      @include mq(s){
              height: 100vh;

      }
    }

    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }

  .image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.picture-contain {
    .image {
      object-fit: contain;
    }
  }

  .placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.5s $ease-custom;

    background-color: whitesmoke;
    will-change: opacity;
  }

  figcaption {
    text-align: left;
  }

  &[data-lazy="true"] {
    .placeholder {
      opacity: 0;
    }
  }
}
</style>
