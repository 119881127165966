<template>
  <nav>
    <Flex
      align="h-around"
      class="menu__flex"
    >
      <div
        v-for="item in navigation"
        :key="item.id"
        class="nav__link"
        :class="[...item.css]"
      >
        <a
          v-if="item.target"
          :href="item.url"
          :target="item.target"
          v-html="item.content"
        />
        <router-link
          v-else
          :to="item.url"
          v-html="item.content"
        />
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Menu',
  computed: {
    ...mapGetters(['menu']),
    navigation() {
      const nav = this.menu('navigation');
      return nav ? nav.items : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu {
  position: absolute;
  z-index: 10;
  top: 70px;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);

  @include mq(m) {
    overflow: visible;
    max-height: 1000px;
    position: static;
    width: auto;
    box-shadow: none;

    .nav__link {
      opacity: 1;
    }
  }
}

.menu__flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background: $white;
  color: $black;
  padding-top: 4.5 * $unit;
  padding-bottom: 5 * $unit;
  font-size: 30px;
  line-height: 150%;

  @include mq(m) {
    width: auto;

    align-items: center;
    font-size: 16px;
    color: $white;
    flex-direction: row;
    background: transparent;
    padding-top: 0 * $unit;
    padding-bottom: 0 * $unit;
  }
}
.menu__open {
  // transform: scaleY(1);
  max-height: 1000px;
  .nav__link {
    opacity: 1;
  }
}

.nav__link {
  opacity: 0;
  transition: opacity 0.4s ease;
  margin-left: 2.4 * $unit;
  @include mq(m) {
    margin-left: 3 * $unit;
    opacity: 1;
  }
}

.link_donare {
  margin-top: 1.2 * $unit;
  @include mq(m) {
    margin-top: 0;
  }
  a {
    padding: 0px 12px 6px 12px;
    border: 1px solid $black;
    transition: all 0.2s ease-in-out;
    @include mq(m) {
      padding: 4px 12px 6px 12px;
      border: 1px solid $white;
    }
  }

  a:hover {
    background: $black;
    color:$white;
    @include mq(m) {
       background: $white;
    color:$red;
    }
  }
}
</style>
