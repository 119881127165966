<template>
  <div class="carousel">
    <slot />
  </div>
</template>

<script>
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

require('flickity-imagesloaded');

export default {
  name: 'Carousel',
  props: {
    extraSettings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      carousel: null,
      settings: {
        cellAlign: 'center',
        cellSelector: '.item',
        imagesLoaded: true,
        groupCells: 3,
        fullscreen: true,

        pageDots: false,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carousel = new Flickity(
        this.$el,
        Object.assign(this.settings, this.extraSettings),
      );
    });
  },
  beforeUnmount() {
    this.carousel.destroy();
  },
};
</script>

<style scoped lang="scss">
.carousel {
  // padding: 0 4.8 * $unit;
}
.flickity-slider{
  transform: translateX(0)!important;
}

.item {
  width: calc(33.3% - 40px);
  padding: 0;
  // aspect-ratio: 0.7;
  border: 1px solid red;
  .spacer{
    background: pink;
    height: 100%;
  }
  .block-content__content{
    height: 100%;
    display: flex!important;
    flex-direction: column;
    justify-content: space-between;
  }

}

</style>
