var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['cta', _vm.data.size ? ("typo--" + (_vm.data.size)) : null],style:({
    color:
      _vm.data.color !== ''
        ? _vm.data.color
        : null,
    backgroundColor:
      _vm.data.background !== ''
        ? _vm.data.background
        : null,
  })},[(_vm.data.fn)?[_c('button',{on:{"click":_vm.data.fn}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:(_vm.data.target === '_blank')?[_c('a',{attrs:{"href":_vm.data.url,"target":_vm.data.target}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]:[_c('router-link',{attrs:{"to":_vm.url}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.title)}}),(_vm.data.toggle)?_vm._t(_vm.data.toggle):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }