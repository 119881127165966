<template>
  <Spacer>
    <Title
      v-if="data.title"
      :data="{ value: data.title }"
    />
    <Grid
      v-if="items.length > 0"
      :col="gridCol"
      :template="gridTemplate"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <Thumb :data="item" />
      </div>
    </Grid>
    <div v-else>
      {{ placeholder }}
    </div>

    <div
      v-show="items && data.infinite_scroll"
      ref="infinite"
      class="infinite"
    />

    <Cta
      v-if="data.cta"
      :data="data.cta"
    />
  </Spacer>
</template>

<script>
import archive from '@/mixins/archive';

import Thumb from '@/components/thumb';
import Title from '@/components/typo/title';
import Cta from '@/components/typo/cta';

export default {
  name: 'Archive',
  components: {
    Thumb,
    Title,
    Cta,
  },
  mixins: [archive],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    gridCol() {
      let cols = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_size } = layout;
          cols = {
            ...cols,
            [media_query]: layout_size === 'auto' ? (12 / this.items.length).toString() : layout_size,
          };
        });
      } else {
        cols = {
          xs: 12,
          m: 4,
        };
      }
      return cols;
    },
    gridTemplate() {
      let template = null;
      if (this.data.layout.length > 0) {
        this.data.layout.forEach((layout) => {
          const { media_query, layout_type } = layout;
          template = {
            ...template,
            [media_query]: layout_type,
          };
        });
      } else {
        template = 'default';
      }
      return template;
    },
  },
};
</script>

<style lang="scss" scoped>
.infinite {
  visibility: hidden;
}
</style>
