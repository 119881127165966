<template>
  <Spacer>
    <div
      class="block-content__content"
      :style="{
        color:
          data.block_text_color !== ''
            ? data.block_text_color
            : null,
        backgroundColor:
          data.block_background_color !== ''
            ? data.block_background_color
            : null,
      } "
    >
      <Label
        v-if="data.label && data.label !== ''"
        :data="{
          value: data.label,
          tag: data.tag_label,
          size: data.size_label
        }"
      />
      <Title
        v-if="data.title && data.title !== ''"
        :data="{
          value: data.title,
          tag: data.tag_title,
          size: data.size_title
        }"
      />
      <RichText
        v-if="data.rich_text && data.rich_text !== ''"
        :data="{
          value: data.rich_text,
          size: data.size_rich_text
        }"
      />
      <Cta
        v-if="data.cta"
        :data="Object.assign(data.cta, {
          color: data.cta_text_color,
          background: data.cta_background_color,
        })"
      />
    </div>
  </Spacer>
</template>

<script>
import Title from '@/components/typo/title';
import Label from '@/components/typo/label';
import RichText from '@/components/typo/richtext';
import Cta from '@/components/typo/cta';

export default {
  name: 'Content',
  components: {
    Title,
    Label,
    RichText,
    Cta,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
