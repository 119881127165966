<template>
  <div>
    <div
      v-if="!success"
      :class="['form', avoid ? 'form-avoid' : false]"
    >
      <Content :data="{ title: data.title, rich_text: data.rich_text }" />
      <form @submit.prevent="submit">
        <input
          v-model="email"
          type="email"
          name="email"
          required
        >
        <button>Send</button>
      </form>
    </div>
    <div v-else>
      ok!
    </div>
  </div>
</template>

<script>
import Content from '@/components/blocks/content';

export default {
  name: 'Newsletter',
  components: {
    Content,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      email: '',
      success: false,
      avoid: false,
    };
  },
  methods: {
    submit() {
      if (this.avoid) return;

      this.avoid = true;
      const request = {
        fn: 'mailchimp',
        params: {
          list_id: this.data.list_id,
          email: this.email,
        },
      };
      this.$store.dispatch('sendRequest', request)
        .then((response) => {
          if (response.data.status && response.data.status === 'subscribed') {
            this.success = true;
          } else {
            this.success = false;
            this.avoid = false;
            console.log(response.data.title);
          }
        })
        .catch((error) => {
          this.success = false;
          this.avoid = false;
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  &-avoid {
    cursor: progress;

    form {
      pointer-events: none;
    }
  }
}
</style>
