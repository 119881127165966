<template>
  <nav
    v-if="post && sectionsInMenu && sectionsInMenu[0]"
    class="page-menu"
    :class="{ nav__up: navUp}"
  >
    <Flex
      v-if="sectionsInMenu"

      align="center"
    >
      <div
        v-for="(section, index) in sectionsInMenu"
        :key="index"
        class="page-menu__item"
      >
        <router-link
          :to="`#${getComponentSlug(section, index)}`"
          :data-section-slug="getComponentSlug(section, index)"
        >
          {{ getSectionTitle(section) }}
        </router-link>
      </div>
    </Flex>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import slugify from 'slugify';
import gsap, { ScrollToPlugin } from 'gsap/all';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import data from '@/mixins/data';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'PageMenu',
  components: {
  },
  mixins: [data],
  props: {

  },
  data() {
    return {
      firstLoading: true,
      prevSection: null,
      prevPosition: 0,
      navUp: false,
    };
  },
  computed: {
    ...mapGetters(['currentPost']),
    sectionsInMenu() {
      if (this.post.acf && this.post.acf.sections) {
        return this.post.acf.sections.filter((x) => x.show_in_page_menu);
      }
      return [];
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === to.path && to.hash !== from.hash && to.hash) {
        this.prevSection = from.hash;
        this.scrollToCurrentSection(to.hash);
      }
    },
    currentPost() {
      if (this.currentPost) {
        if (this.firstLoading === true && this.$route.hash) {
          this.scrollToCurrentSection(this.$route.hash);
        }
        this.firstLoading = false;
      }
    },
  },
  mounted() {
    document.addEventListener('scroll', this.moveHeader);
  },
  methods: {
    moveHeader() {
      const currentScrollPos = window.pageYOffset;
      if (this.prevScrollpos > currentScrollPos) {
        this.navUp = false;
      } else if (currentScrollPos) {
        this.navUp = true;
      }
      this.prevScrollpos = currentScrollPos;
    },
    getSectionTitle(section) {
      if (section.page_menu_label) {
        return section.page_menu_label;
      }
      if (section.blocks && section.blocks[0] && section.blocks[0].title) {
        return section.blocks[0].title;
      }
      return '';
    },
    getComponentSlug(section, index) {
      let label = this.getSectionTitle(section);
      if (section.section_id) {
        label = section.section_id;
      }
      return label ? `${slugify(label, {
        lower: true,
        strict: true,
      })}` : `section-${index + 1}`;
    },
    scrollToCurrentSection(sectionID) {
      if (!document.querySelector(sectionID)) {
        return;
      }
      disableBodyScroll(this.$el);
      let offset = 0;
      let direction = 'down';
      if (document.querySelector(sectionID) && document.querySelector(sectionID).offsetTop <= this.prevPosition) {
        direction = 'up';
      }
      if (document.querySelector('header')) {
        offset += document.querySelector('header').offsetHeight;
      }
      if (document.querySelector('.page-menu') && direction === 'up') {
        offset += document.querySelector('.page-menu').offsetHeight;
      }
      gsap.killTweensOf(window);
      gsap.to(window, {
        scrollTo: {
          y: document.querySelector(sectionID),
          offsetY: offset,
          // autokill: true,
        },
        duration: 0.4,
        ease: 'expo.out',
        onComplete: () => {
          clearAllBodyScrollLocks();
          this.prevPosition = window.scrollY;
        },
      });
    },
  },
};
</script>

<style lang="scss">

.page-menu {
  background-color: $white;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 100;
  @extend %sans;
  @extend %typo--s;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease-in-out;
  display: none;
    padding: 1.2*$unit 0 ;
  @include mq(s){
    display: block;
  }
}
.page-menu__item{
  margin: 0 1.5*$unit;
}
.nav__up{
  transform: translateY(-100%);
}

</style>
