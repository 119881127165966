<template>
  <div
    :class="['cta', data.size ? `typo--${data.size}` : null]"
    :style="{
      color:
        data.color !== ''
          ? data.color
          : null,
      backgroundColor:
        data.background !== ''
          ? data.background
          : null,
    }"
  >
    <template v-if="data.fn">
      <button @click="data.fn">
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </button>
    </template>
    <template v-else-if="data.target === '_blank'">
      <a
        :href="data.url"
        :target="data.target"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </a>
    </template>
    <template v-else>
      <router-link
        :to="url"
      >
        <div v-html="data.title" />
        <slot
          v-if="data.toggle"
          :name="data.toggle"
        />
      </router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Cta',

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    url() {
      if (process.env.VUE_APP_BASE_URL && process.env.VUE_APP_BASE_URL !== '/' && this.data.url.indexOf('http') < 0) {
        return this.data.url.replace(process.env.VUE_APP_BASE_URL, '');
      }
      return this.data.url;
    },
    mergeProps() {
      const defaultProps = {
        value: '',
        color: '#222',
        background: '#FBF8F5',
      };

      Object.keys(this.data).forEach((key) => this.data[key] === undefined && delete this.data[key]);
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  display: inline-block;
}
</style>
