export default {
  lang: (state) => state.lang,
  defaultLang: (state) => state.wp.i18n.default,
  langs: (state) => state.wp.i18n.langs,
  menu: (state, getters) => (name) => state.wp.menus[getters.lang][name],
  request: (state) => ({ type, params }) => {
    let res = null;
    const slugs = [];
    if (params.slug && params.slug.length > 0) {
      params.slug.forEach((slug) => {
        if (state.wp[type][params.lang][slug]) slugs.push(slug);
      });
      return slugs.length > 0 ? slugs : null;
    }

    const request = state.wp[type].requests.find((req) => {
      if (Object.keys(req.params).length === Object.keys(params).length) {
        res = Object.keys(req.params).every((key) => JSON.stringify(req.params[key]) === JSON.stringify(params[key]));
      }
      return res;
    });

    if (request) return request.data;
    return res;
  },
  requestedItems: (state, getters) => ({ type, params }) => {
    const request = getters.request({ type, params });
    return request ? request.data.map((id) => state.wp[type][id]) : [];
  },
  singleBySlug: (state) => ({ type, slug, lang }) => state.wp[type][lang][slug],
  isTouch: (state) => state.touch,
  isMobile: (state) => state.mobile,
  breakpoints: (state) => state.breakpoints,
  currentPost: (state) => state.currentPost,
};
