<template>
  <router-link :to="data.link">
    <Figure
      v-if="data.gds_featured_image"
      :data="data.gds_featured_image"
    />
    <Title :data="{ value: data.taxonomy ? data.name : data.title.rendered }" />
  </router-link>
</template>

<script>
import Figure from '@/components/media/figure';
import Title from '@/components/typo/title';

export default {
  name: 'Thumb',
  components: {
    Figure,
    Title,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>

</style>
