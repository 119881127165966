<template>
  <div>
    <component
      :is="tag"
      v-if="currentTemplate === 'grid'"
      :class="[
        'grid',
        ...gridCol,
        ...extraClass,
      ]"
    >
      <slot />
    </component>
    <Carousel
      v-if="currentTemplate === 'carousel'"
      :col="col"
    >
      <slot />
    </Carousel>
  </div>
</template>

<script>
import { is } from '@/assets/js/utils';

export default {
  name: 'Grid',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    col: {
      type: [Object, String],
      default: '',
    },
    template: {
      type: [Object, String, Array],
      default: 'grid',
    },
    extraClass: {
      type: [Array],
      default: () => [],
    },
  },
  data() {
    return {
      currentTemplate: 'grid',
      stopSignal: false,
    };
  },
  computed: {
    gridCol() {
      const { col } = this;
      const cols = [];
      if (typeof col === 'string' && col !== '') {
        cols.push(`grid-${col}`);
      } else if (typeof col === 'object') {
        Object.keys(col).forEach((key) => {
          if (key !== 'default') {
            cols.push(`grid-${key}-${col[key]}`);
          } else {
            cols.push(`grid-${col[key]}`);
          }
        });
      }

      return cols;
    },
  },
  mounted() {
    if (typeof this.template === 'object') {
      this.watchLayout();
      this.$bus.$on('windowResized', this.watchLayout);
    }
  },
  beforeUnmount() {
    this.$bus.$off('windowResized', this.watchLayout);
  },
  methods: {
    watchLayout() {
      this.stopSignal = false;
      Object.keys(this.template).forEach((key, index) => {
        if (is(key) && !this.stopSignal) {
          this.stopSignal = true;
          this.currentTemplate = this.template[key];
        } else if (!this.stopSignal && index === (Object.keys(this.template).length - 1)) {
          this.currentTemplate = this.template[key];
        }
      });
    },
  },
};
</script>

<style lang="scss">
// Grid squared
// .grid {
//   .item {
//     position: relative;
//     overflow: hidden;
//     &:before {
//       content: "";
//       width: 100vw;
//       padding-bottom: 100%;
//       display: block;
//     }
//   }
//   .block-content__content {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }
// }
</style>
