var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Spacer',[(_vm.data.title)?_c('Title',{attrs:{"data":{ value: _vm.data.title }}}):_vm._e(),_c('Grid',{attrs:{"col":_vm.gridCol,"template":_vm.gridTemplate}},_vm._l((_vm.data.items),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
        color:
          _vm.data.items[index].block_text_color !== ''
            ? _vm.data.items[index].block_text_color
            : null,
        backgroundColor:
          _vm.data.items[index].block_background_color !== ''
            ? _vm.data.items[index].block_background_color
            : null,
      })},[(_vm.checkMedia(item))?_c('Media',{attrs:{"data":_vm.setMedia(item)}}):_vm._e(),(_vm.checkContent(item))?_c('Content',{attrs:{"data":_vm.setContent(item)}}):_vm._e()],1)}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }