<template>
  <div
    :class="['wysiwyg', mergeProps.size ? `typo--${mergeProps.size}` : null]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Wysiwyg',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      const defaultProps = {
        value: '',
        size: 's',
      };

      Object.keys(this.data).forEach((key) => this.data[key] === undefined && delete this.data[key]);
      return { ...defaultProps, ...this.data };
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/src/03_typography';

.wysiwyg {
  ul, ol {
    display: block;
    margin: 1em 0;
    padding-left: $unit*2;
  }

  ul {
    list-style-type: disc;
  }
}
</style>
