<template>
  <div class="results">
    <Spacer>
      <Grid
        v-if="results.length > 0"
        :col="{ default: 12, m: 4 }"
      >
        <div
          v-for="(item, index) in results"
          :key="index"
          class="item"
        >
          <Thumb :data="item" />
        </div>
      </Grid>
      <div v-else-if="results.length === 0 && !found">
        {{ emptyState }}
      </div>
      <div v-else>
        {{ placeholder }}
      </div>
    </Spacer>
  </div>
</template>

<script>
import groupBy from 'lodash.groupby';

import Thumb from '@/components/thumb';

export default {
  name: 'Search',
  components: {
    Thumb,
  },
  data() {
    return {
      results: [],
      found: true,
      placeholder: 'Searching...',
      emptyState: 'Found nothing',
    };
  },
  watch: {
    $route(to, old) {
      if (to.query.s && to.query.s !== old.query.s) {
        this.setRequest(to.query.s);
      }
    },
  },
  mounted() {
    if (this.$route.query.s) this.setRequest(this.$route.query.s);
  },
  methods: {
    setRequest(params) {
      this.$store.dispatch('getSearch', params).then((response) => {
        if (response.data && response.data.length > 0) {
          this.found = true;
          const types = groupBy(response.data, 'subtype');
          const promises = [];
          Object.keys(types).map((type) => {
            const include = types[type].reduce((a, o) => ((a.push(o.id), a)), []);
            const request = {
              type: `${type}s`,
              params: {
                include,
                per_page: 100,
                offset: 0,
              },
            };
            return promises.push(this.$store.dispatch('getItems', request));
          });

          Promise.all(promises).then((res) => {
            if (res) {
              this.results = [];
              res.map((el) => this.results.push(...el));
            }
          });
        } else {
          this.found = false;
          this.results = [];
        }
      });
    },
  },
};
</script>
