var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',_vm._l((_vm.post.acf.sections),function(section,parentIndex){return _c('Section',{key:parentIndex,class:("section--" + parentIndex),attrs:{"layout":_vm.layout(section)}},[_c('div',{staticClass:"section__title"},[_c('h2',[_vm._v(" "+_vm._s(_vm.post.acf.sections[parentIndex].title)+" ")])]),_c('div',{staticClass:"section__blocks",class:[
        _vm.post.acf.sections[parentIndex].section_text === 'inverse'
          ? 'section--text-inverse'
          : null ],style:({
        backgroundColor:
          _vm.post.acf.sections[parentIndex].section_background !== ''
            ? _vm.post.acf.sections[parentIndex].section_background
            : null,
      })},_vm._l((section.blocks),function(block,index){return _c(_vm.components.get(block.acf_fc_layout),{key:index,tag:"component",class:("block-" + (block.acf_fc_layout)),attrs:{"data":block}})}),1)])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }