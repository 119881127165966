<template>
  <header>
    <Spacer
      :class="{'menu__open' : menuOpen }"
      :x="{default: 'm', m: 'l'}"
    >
      <Flex align="h-around">
        <router-link to="/">
          <div class="logo" />
        </router-link>

        <div
          class="burger menu__button"
          @click="toggleMenu"
        >
          <div class="line" />
          <div class="line" />
          <div class="line" />
        </div>

        <Menu
          class="menu"
          :class="{'menu__open' : menuOpen }"
        />
      </Flex>
      <Spacer />
    </Spacer>
  </header>
</template>

<script>
import Menu from '@/components/ui/menu';

export default {
  name: 'Header',
  components: {
    Menu,
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to !== from) {
        this.menuOpen = false;
      }
    },
  },
  mounted() {
  },
  methods: {

    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },

  },
};
</script>

<style lang="scss" scoped>
header {
  background: $red;
  color: $white;
  height: 7 * $unit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 102;
}
.nav__up{
  transform: translateY(-100%);
}
.logo {
  width: 240px;
  height: 24px;
  background-image: url("~@/assets/img/logo.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.spacer {
  width: 100%;
}
nav {
  margin-left: auto;
  display: flex;
  align-items: center;
  @extend %typo--s;
}
.menu__button{
  margin-left: auto;
  @include mq(m){
    display: none;
  }
}

  .burger {
    position: relative;
    width: 22px;
    height: 20px;
    cursor: pointer;

    .line {
      position: absolute;
      left: 0;
      top: 10px;
      width: 100%;
      height: 2px;
      background: $white;
      transition: transform 0.3s $ease-custom;

      &:nth-child(1) {
        transform: translateY(-8px);
      }
      &:nth-child(3) {
        transform: translateY(8px);
      }
    }
  }

  .menu__open {
    .burger {
      .line {
        &:nth-child(1) {
          transform: translateY(0px) rotate(-45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:nth-child(3) {
          transform: translateY(0px) rotate(45deg);
        }
      }
    }
  }

</style>
