export default {
  ADD_ITEM(state, { type, item, lang }) {
    if (item && type && !state.wp[type][lang][item.slug]) {
      state.wp[type][lang][item.slug] = item;
    }
  },
  ADD_LANGUAGES(state, value) {
    value.forEach((lang) => {
      state.wp.pages[lang] = {};

      Object.keys(state.wp.postTypes).forEach((key) => {
        const restBase = state.wp.postTypes[key].rest_base;
        state.wp[restBase][lang] = {};
      });
    });
  },
  ADD_REQUEST(state, { type, request }) {
    state.wp[type].requests.push(request);
  },
  SET_CURRENT_POST(state, value) {
    state.currentPost = value;
  },
  SET_TOUCH(state, value) {
    state.touch = value;
  },
  SET_BREAKPOINTS(state, value) {
    state.breakpoints = value;
  },
  SET_MOBILE(state, value) {
    state.mobile = value;
  },
  SET_LANG(state, value) {
    state.lang = value;
  },
};
