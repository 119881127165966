<template>
  <div v-html="data.raw_html" />
</template>

<script>
export default {
  name: 'RawHtml',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.paypal-form {
  padding-top: 1*$unit;
  text-align: center;

  @include mq(s) {
    padding-top: 2*$unit;

  }
}

</style>
