<template>
  <main>
    <Section
      v-for="(section, parentIndex) in post.acf.sections"
      :key="parentIndex"
      :layout="layout(section)"
      :class="`section--${parentIndex}`"
    >
      <div class="section__title">
        <h2>
          {{ post.acf.sections[parentIndex].title }}
        </h2>
      </div>

      <div
        class="section__blocks"
        :class="[
          post.acf.sections[parentIndex].section_text === 'inverse'
            ? 'section--text-inverse'
            : null,
        ]"
        :style="{
          backgroundColor:
            post.acf.sections[parentIndex].section_background !== ''
              ? post.acf.sections[parentIndex].section_background
              : null,
        }"
      >
        <component
          :is="components.get(block.acf_fc_layout)"
          v-for="(block, index) in section.blocks"
          :key="index"
          :class="`block-${block.acf_fc_layout}`"
          :data="block"
        />
      </div>
    </Section>
  </main>
</template>

<script>
import components from '@/assets/js/components';
import data from '@/mixins/data';

import Section from '@/components/section';
import Content from '@/components/blocks/content';
import Media from '@/components/blocks/media';
import Repeater from '@/components/blocks/repeater';
import List from '@/components/blocks/list';
import Archive from '@/components/blocks/archive';
import Custom from '@/components/blocks/custom';
import Newsletter from '@/components/blocks/newsletter';
import Hero from '@/components/blocks/hero';
import Accordion from '@/components/blocks/accordion';
import Marquee from '@/components/blocks/marquee';
import Search from '@/components/blocks/search';
import RawHtml from '@/components/blocks/raw-html';

export default {
  name: 'Page',
  components: {
    Section,
    Content,
    Media,
    Repeater,
    List,
    Archive,
    Custom,
    Newsletter,
    Hero,
    Accordion,
    Marquee,
    Search,
    RawHtml,
  },
  mixins: [data],
  data() {
    return {
      components,
    };
  },
  methods: {
    layout(section) {
      const {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
      } = section;
      return {
        section_background,
        section_classes,
        section_layout,
        section_layout_custom,
        section_text,
        section_id,
      };
    },
  },
};
</script>
