import axios from 'axios';

// eslint-disable-next-line no-undef
let { url } = __VUE_WORDPRESS__.routing;
url = url.replace(':8888', ':3000');

const urlWp = `${url}/wp-json/wp/v2/`;

const ajax = axios.create(
  {
    baseURL: urlWp,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
);

const batchRequest = {};

const batchRequestIds = (type) => ajax.get(`/${type}/`, { params: { include: batchRequest[type].ids, per_page: 100 } });

const addBatchId = (type, id) => {
  if (!batchRequest[type]) {
    batchRequest[type] = {};
    batchRequest[type].ids = [id];
    batchRequest[type].request = new Promise((resolve) => {
      setTimeout(() => {
        resolve(batchRequestIds(type));
        batchRequest[type] = null;
      }, 100);
    });
  } else if (!batchRequest[type].ids.includes(id)) {
    batchRequest[type].ids.push(id);
  }
};

export const fetchSingleById = ({ type, id, batch = false }) => {
  if (batch) {
    addBatchId(type, id);
    return batchRequest[type].request;
  }
  return ajax.get(`/${type}/${id}`);
};

export const fetchSingle = ({ type, params = {} }) => ajax.get(`/${type}/`, { params });

export const fetchItems = ({ type, params = {} }) => ajax.get(`/${type}/`, { params });

export const fetchSearch = (search) => ajax.get(`/search/?search=${search}`);

export const fetchPrivacyPolicy = (id) => axios.get(`https://www.iubenda.com/api/privacy-policy/${id}`, {});

export const sendAjax = (request) => {
  const formData = new FormData();

  formData.append('action', 'do_ajax');
  formData.append('fn', request.fn);
  formData.append('params', JSON.stringify(request.params));

  return axios.post(`${url}/wp-admin/admin-ajax.php`, formData);
};
